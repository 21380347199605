.kiria__features-container__feature {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    flex-direction: row;
    margin: 1rem;
}
.kiria__features-container__featureWhat {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: row;
    margin: 1rem;
}

.kiria__features-container__feature-title {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    align-self: flex-start;
    max-width: 180px;
    margin-right: 2rem;
}

.kiria__features-container__feature-title h1 {
    font-family: var(--font-family);
font-weight: 800;
font-size: 16px;
line-height: 24px;
letter-spacing: -0.04em;
color: #FFFFFF;

}

.kiria__features-container__feature-title div {
    width: 38px;
    height: 3px;
    background: var(--gradient-bar);
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
    margin-bottom: 0.25rem;
}

.kiria__features-container_feature-text {
    flex: 2;
    max-width: 390px;
    display: flex;
}

.kiria__features-container_feature-text p {
    font-family: var(--font-family);
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    color: var(--color-text);
}

@media screen and (max-width: 550px) {
    .kiria__features-container__feature-title h1 {
        font-size: 14px;
        line-height: 22px;
    } 
    .kiria__features-container__feature-title p {
        font-size: 12px;
        line-height: 20px;
    }
    .kiria__features-container__feature {
        margin: 1rem 0;
    } 
}