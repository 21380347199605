@import url('https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,100..900;1,100..900&display=swap');

.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #ffffff !important;
  top: 0;
  width: 100%;
  padding: 10px 20px;
  z-index: 1000; /* Asegura que la navbar esté siempre encima de otros elementos */
  font-family: "Raleway";
  border: 1px solid black;
  box-shadow: none !important;
}

.navbar-logo img {
  height: 70px; /* Ajusta el tamaño del logo según sea necesario */
  display: block !important;
  width: fit-content;
}
.navbar_content{
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.navbar-links {
  display: flex;
  list-style: none;
  margin: 0;
  width: 500px;
  padding: 0;
}

.navbar-links a {
  color: black;
  text-decoration: none;
  font-size: 30px;
  margin-left: 50px;
}

.navbar-links a:hover {
  text-decoration: underline;
}

.menu-icon {
  display: none !important;
}

@media (max-width: 768px) {
  .navbar {
  padding-left: 0px;
  }
  .navbar-links {
    display: none !important;
  }
  .navbar {
    background-color: #ffffff !important;
    justify-content: space-between !important; /* Asegura que los elementos se distribuyan adecuadamente */

  }
  .navbar_content{
    padding: 0px !important;
  }
  .menu-icon {
    display: block !important;
  }
  .navbar-logo img {
    width: 40% !important;
    left: 15%;
  }
}