@import url('https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,100..900;1,100..900&display=swap');

@font-face {
  font-family: 'LeMurmure';
  src: url('../../../../../public/fonts/LeMurmure-Regular.woff2') format('woff2'),
       url('../../../../../public/fonts/LeMurmure-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

.centered {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  height: 100%; /* Ensure the column takes up the full height */
}
.background_jod {
  background-color: #F5F5F5;
  flex-direction: column;
}
.name_jod{
  left: 22%;
  top:-13%;
  position: absolute;
}
.banner_background_section {
  height: 831px;
  width: 100%;
  background-image: url('https://kiriaweb.s3.amazonaws.com/JOD-Portfolio/home/Banner-home-1.png'); 
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  text-align: center;
  flex-direction: column; /* Asegura que los elementos hijos se apilen verticalmente */
  position: relative; /* Necesario para posicionar elementos absolutamente dentro del Box */
}
.banner_eyes{
  position: absolute;
  top: 0;
  left: 0;
}
.title_name{
  background-color: transparent;
    height: auto;
    letter-spacing: 0.00px;
    line-height: normal;
    position: absolute;
    text-align: left;
    top: 218px;
    white-space: nowrap;
    width: auto;
}
.title_lastname{
  height: 238px;
  left: 187px;
  position: absolute;
  top: 505px;
  width: 436px;
}
.title_lastname_2{
  background-color: transparent;
  color: var(--black);
  font-style: normal;
  font-weight: 600 !important;
  height: auto;
  left: 196px;
  letter-spacing: 0.48438em !important;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 776px;
  white-space: nowrap;
  width: 468px;

}

.category_titles {
  margin-top: 20px !important;
  font-family: 'Raleway', sans-serif !important;
  font-weight: 700 !important;
  font-size: 30px !important;
  letter-spacing: 0.3em !important;
  position: absolute !important;
  bottom: 11px !important;
  right: 0 !important;
  background-color: rgba(0, 0, 0, 0.5) !important;
  color: white !important;
  padding: 5px 10px !important;
}

.banner_background_section_2 {
  height: 900px;
  width: 100%;
  padding-top: 1%;
  background-image: url('https://kiriaweb.s3.amazonaws.com/JOD-Portfolio/home/home-texture-2.png'); 
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  text-align: center;
  flex-direction: column; /* Asegura que los elementos hijos se apilen verticalmente */
  position: relative; /* Necesario para posicionar elementos absolutamente dentro del Box */
  margin-bottom: 40px;
}
.who{
  position: absolute;
  text-align: left;
  top:5%;
  left: 8%;
  letter-spacing: 0.2em !important;
  font-weight: 400 !important;
}
.iam{
  position: absolute;
  text-align: right;
  bottom: -5%;
  letter-spacing: 0.2em !important;
  font-weight: 400 !important;

}
.fullSize{
  height: auto;
  width: 100%;
  margin: -2px;
}
.JOD_photo {
   width: '577px'; 
   height: '550px';
}
.passionate_text {
  font-style: normal;
  line-height: 1.3!important;
  font-size: 30px !important;
  font-weight: 400;
  height: auto;
  left: 134px;
  letter-spacing: 0.00px;
  line-height: normal;
  position: absolute;
  text-align: left;
  top: 40%;
  width: 700px;
}
.banner_background_section_3 {
  margin-top: 20px;
  height: 880px;
  width: 100%;
  padding-top: 1%;
  background-image: url('https://kiriaweb.s3.amazonaws.com/JOD-Portfolio/home/Background-Gallery.png'); 
  background-size: cover;
  background-position: center; 
  background-repeat: repeat-x; 
}
.JOD_photo_gallery {
  width: '657px'; 
  height: '493px';
}
.gallery{
  letter-spacing: 0.1em !important;
  background-color: #ffffffbd;
}
.gallery_grid{
  padding: 10px;
}
.footer_jod{
  background-color: black;
  color:white;
  width: 100%;
  margin-top: 30px !important;
}
.logo_footer{
  height: 60px;
 }
 .two_images {
  height: 1000px; /* Adjust this height as needed */
  object-fit: cover;
  margin: 0;
}
/* Media queries for mobile responsiveness ---------------------------------------------*/
@media (max-width: 1000px) {

  .banner_eyes{
    position: absolute !important;
    height: 100px;
    top: 0;
    left: 0;

  }
  .title_name{
    position: relative;
    font-size: 136px !important; 
    width: 100%;

  }
  .title_lastname {
    position: relative;
    font-size: 136px !important; 
    margin-left: 10% !important;
    margin-bottom: 0px !important;
    height: 238px;
    left: 30px;
    width: 100%;
    top: 200px;
  }
  .title_lastname_2 {
    font-size: 28px !important;
    margin-left: 10% !important;
    position: relative;
    left: 45px;
    top: 100px;
    width: 100%;
  }
  .category_titles {
    font-size: 26px !important; 
    margin-bottom: 20px;
  }
  .banner_background_section {
    width: 100% !important;
    background-image: url('https://kiriaweb.s3.amazonaws.com/JOD-Portfolio/home/Banner-home-1.png'); 
    background-size: cover;
    background-position: right;
    background-repeat: no-repeat;

  }
  .who{
    font-size: 80px !important;
    left: 12%;
  }
  .iam{
    font-size: 80px !important;
    text-align: right;
    left: 50%;
    top:5%;
    bottom: 100%;
    padding-left: 18px !important;
  }
  .JOD_photo {
    top:-22%;
    position: relative;
    width: 100%; 
    height: '550px';
 }
 .passionate_text {
   font-style: normal;
   line-height: 1.3!important;
   font-size: 18px !important;
   font-weight: 400;
   height: auto;
   left: 50px;
   letter-spacing: 0.00px;
   line-height: normal;
   position: absolute;
   text-align: left;
   top: 63%;
   width: 88%;
 }
 .banner_background_section_3 {
    height: 400px; 
    margin: 0px;
  }
  .gallery_grid{
    padding: 0px;

  }
 .JOD_photo_gallery {
  width: 350px !important; 
  height: 213px !important;
}
.gallery{
  font-size: 120px !important;
  letter-spacing: 0.1em !important;
  background-color: #ffffffbd;
}
 .logo_footer{
  height: 100px;
 } 
 .two_images {
  height: 200px; 
}

}